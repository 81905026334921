import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';

onDomReady(() => {
   HeaderLanguages.initialize();
});

const HeaderLanguages = {
   initialize: function () {
      $$('.language-option').addEvent('click', function (ev) {
         let form = this.getParent('form');
         let langidInput = form.getChildren('.langidInput');
         const newLangid = this.get('data-langid');

         if (newLangid) {
            langidInput.set('value', newLangid);
         }

         form.submit();

         if (!App.canIBypassGAEventFiringOnMobile && App.lang != newLangid) {
            trackInPiwikAndGA({
               eventCategory: 'Language',
               eventAction: `Language Switcher - ${App.lang} - ${newLangid}`,
            });
         }

         ev.stop();
      });
   },
};
